import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, Stack, Typography } from "@mui/material";

// import Transaction from './transaction';
import { useEffect, useState } from "react";
import PayoutCard from "../../components/Common/card";
import { useTheme } from "@emotion/react";
import { toast } from "react-toastify";
import { getPayoutSummery } from "../../helpers/backend_helper";
import { Container } from "reactstrap";
import TransactionPayout from "./transaction";
// import UnitDetailsModel from './unitDetailsModel';

const Settlement = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [payoutDetails, setPayoutDetails] = useState([]);
  const theme = useTheme();
  const handleManagePopup = () => {
    setIsOpen(!isOpen);
  };
  const fetchPayouttData = async () => {
    await getPayoutSummery()
      .then((res) => {
        console.log(res.data);

        setPayoutDetails(res?.data || []);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
      });
  };
  useEffect(() => {
    fetchPayouttData();
  }, []);
  const newLocal = (
    <Stack
      direction={"row"}
      flexWrap={"wrap"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Typography variant="Body_light_20" fontWeight={600}>
        Payout History
      </Typography>
    </Stack>
  );
  return (
    <>
      <div className="page-content">
        <Container fluid>
          {newLocal}

          <Grid container spacing={2} my={0.5}>
            <>
              <Grid item md={4} xs={12}>
                <PayoutCard sx={{ boxShadow: "none" }}>
                  <Stack direction={"column"} gap={1.5} >
                    <Typography variant="Body_semibold_16" color="text.orange">
                      Total Settlement Payment
                    </Typography>
                    <Stack direction={"row"} alignItems={"baseline"} gap={1} marginLeft="10px">
                      <Typography variant="Body_medium_32" textAlign="center" fontWeight={700}>
                        ₦ {payoutDetails.totalPayment}
                      </Typography>
                    </Stack>
                  </Stack>
                </PayoutCard>
              </Grid>
            </>
          </Grid>
          <TransactionPayout />
        </Container>
      </div>
    </>
  );
};

export default Settlement;
