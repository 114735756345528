import React from "react";
import { Redirect } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

import Clients from "../pages/Clients";

// pricing plan
import PagesPricing from "../pages/Authentication/PagePricing";
// registration success
// import RegistrationSuccess from "../pages/Utility/pages-successfully"
// Authentication related pages
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// Profile
import ChangePassword from "../pages/Authentication/ChangePassword";
import ChangePasswordPage from "../pages/Authentication/ConfirmChangePassword";
import CreatePassword from "../pages/Authentication/CreatePassword";
import Plan from "../pages/Authentication/Plan";
import ResetPassword from "../pages/Authentication/ResetPassword";
import TermCondition from "../pages/Authentication/TermAndCondition";
import VerifyUser from "../pages/Authentication/VerifyUser";
import RegistrationSuccessful from "../pages/Utility/register-success";

// Dashboard UI
import ContactsList from '../pages/Authentication/AdminUsersList/admin-setting-list';
import SelectPlan from '../pages/Authentication/ChoosePlan';
import UpdatePassword from "../pages/Authentication/UpdatePassword";
import IncomeAndJobVerification from "../pages/IncomeAndJobVerification";
import CategoryInsights from "../pages/category-insights";
import ExpandList from "../pages/category-insights/ExpandList";
import DashboardOverview from "../pages/dashboard-ui/Overview";
import DirectDebit from '../pages/direct-debit';
import CustomerInfo from '../pages/direct-debit/CustomeInfo';
import ProofOfFunds from "../pages/proofOfFunds";
import RiskAndInsight from "../pages/risk-and-insight";
import Statement from '../pages/statement';
import Wallet from '../pages/wallet';
import Settings  from "../pages/settings";
import Sattlement from "../pages/settements";

const userRoutes = [
  // { path: "/dashboard", component: Dashboard },
  { path: '/client/dashboard/:id', component: Dashboard },
  { path: '/admin-users', component: ContactsList },
  { path: '/wallet', component: Wallet },
  { path: '/direct-debit', component: DirectDebit },
  { path: '/direct-debit/:id', component: CustomerInfo },
  { path: '/settlement', component: Sattlement },

  //Client List
  { path: '/client-list', component: Clients },

  { path: '/update-password', component: UpdatePassword },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to="/client-list" /> },
  { path: "/settings", component: Settings },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/confrim-change-password", component: ChangePasswordPage },

  // registration
  { path: "/registration-success", component: RegistrationSuccessful },
  // { path: "/registration-failed", component: RegistrationFailed},
  // Authentication Inner

  // reset password
  { path: "/reset-password", component: ResetPassword },
  // change password
  { path: "/changePassword", component: ChangePassword },
  // reset password
  { path: "/createSecondaryUserPassword/:token", component: CreatePassword },
  // verify user
  { path: "/activateBusinessPartnerUsers/:token", component: VerifyUser },
  // verify user
  { path: "/termcondition", component: TermCondition },
  // plan
  { path: "/plans", component: Plan },
  // pricing plan
  { path: "/plan", component: PagesPricing },
  { path: "/choose-Plan/:token", component: SelectPlan },
];

const frameRoutes = [
  { path: "/client/viewOnly/:id", component: Dashboard },
  // Dashboard Overview
  { path: "/dashboard-overview", component: DashboardOverview },

  { path: "/risk-and-insight", component: RiskAndInsight },

  { path: "/category-insights", component: CategoryInsights },
  { path: "/expand-list", component: ExpandList },

  { path: "/statement", component: Statement },

  { path: "/kyc", component: IncomeAndJobVerification },
  { path: "/proof-of-funds", component: ProofOfFunds },
];

export { authRoutes, frameRoutes, userRoutes };
